<nav class="navbar fixed-top navbar-expand-lg bg-color navbar-dark">
  <div class="container-fluid">

    @if (isLoggedIn$ | async) {

      <a class="navbar-brand" (click)="navigate()">
        <img class="img-fluid d-inline-block align-text-top sf-logo" src="./assets/sf_logo_neg.svg"/>
        {{ titleStr }}

        @if (envStr) {
          <span [ngClass]="[envBgClass]" class="badge">{{ envStr }}</span>
        }
      </a>

      @if (currentWorkstation) {
        <span class="navbar-text">
          <h5 class="mx-0 text-yellow">{{ currentWorkstation }}</h5>
        </span>
      }

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="#navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

    } @else {

      <a class="navbar-brand" [routerLink]="['./signin']">
        <img class="img-fluid d-inline-block align-text-top sf-logo" src="./assets/sf_logo_neg.svg"/>
        {{ titleStr }}

        @if (envStr) {
          <span [ngClass]="[envBgClass]" class="badge">{{ envStr }}</span>
        }
      </a>

    }

    <div class="collapse navbar-collapse" id="navbarMenu">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

        <li class="nav-item">
          @if (isLoggedIn$ | async) {
            <pp-signout class="float-right" (signOutEvent)="signOutEvent()" [userData]="userData"></pp-signout>
          }
        </li>

      </ul>
    </div>

  </div>
</nav>