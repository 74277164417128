import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, catchError, map, timeout, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { User } from '../models/user.model'
import { TokenService } from './token.service';
import { environment } from '../../../environments/environment';

const AUTH_URL = environment.frontendApi + '/userservice/authenticate';
const TIMEOUT = 30 * 1000;

const HTTP_HEADERS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loggedIn: BehaviorSubject<boolean>;
  private userData: BehaviorSubject<User>;

  get isLoggedIn() { return this.loggedIn.asObservable(); }
  get loggedInUser() { return this.userData.asObservable(); }

  constructor(private httpClient: HttpClient, private tokenService: TokenService, private router: Router) {
    this.loggedIn = new BehaviorSubject<boolean>(this.isAuthenticated());
    this.userData = new BehaviorSubject<User>(this.tokenService.getUserData());
  }

  authenticate(username: string, password: string): Observable<User> {
    return this.httpClient.post<User>(
      AUTH_URL,
      {
        'username': username.trim(),
        'password': password.trim()
      },
      HTTP_HEADERS
    )
    .pipe(
      timeout(TIMEOUT),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  setUserDataAndReRoute(user: User) {
    this.tokenService.setUserData(user);

    this.loggedIn.next(true);
    this.userData.next(user);

    // Route to landing page.
    this.router.navigate(['/start']);
  }

  isAuthenticated(): boolean {
    return !!this.tokenService.getToken();
  }

  deAuthenticate(): void {
    this.loggedIn.next(false);
    this.userData.next({} as User);
    this.tokenService.clear();
  }
}
