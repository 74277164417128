import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { environment } from './../../../../environments/environment';
import { User } from '../../models/user.model';

import { AuthenticationService } from '../../services/authentication.service';
import { CurrentPageStateService } from '../../services/current-page-state.service';
import { LocalClientService } from '../../services/local-client.service';

import { SignoutComponent } from '../signout/signout.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SignoutComponent],
  selector: 'pp-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnDestroy {

  #authenticationService = inject(AuthenticationService)
  #currentPageStateService = inject(CurrentPageStateService)
  #localClient = inject(LocalClientService)
  #router = inject(Router)

  private destroy$: Subject<void> = new Subject<void>();

  isLoggedIn$ = this.#authenticationService.isLoggedIn
  loggedInUser$ = this.#authenticationService.loggedInUser
  userData: User = {} as User;
  currentWorkstation: string = '';
  titleStr: string = environment.titleStr;
  envStr: string = environment.envStr;
  envBgClass: string = environment.envBgClass;

  ngOnInit() {
    this.loggedInUser$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => {
      this.userData = user
    })

    this.#currentPageStateService.currentPage$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(title => {
      this.currentWorkstation = title ?? '';
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  signOutEvent() {
    this.#router.navigate(['/']);
  }

  navigate() {
    this.#localClient.setWorkstationRoute('');

    if (this.#localClient.hasData()) {
      this.#localClient.deleteAll();
    }

    this.#router.navigate(['/start']);
  }
}
