import { Injectable } from '@angular/core';
import { User } from '../models/user.model';

const USER_DATA = 'user-data';
const USER_TOKEN = 'user-token';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  setToken(token: string): void {
    window.sessionStorage.setItem(USER_TOKEN, token);
  }

  getToken(): string | null {
    return window.sessionStorage.getItem(USER_TOKEN);
  }

  setUserData(user: User): void {
    window.sessionStorage.setItem(USER_DATA, JSON.stringify(user));
    this.setToken(user.token);
  }

  getUserData(): any {
    const user = window.sessionStorage.getItem(USER_DATA);
    return user ? JSON.parse(user) : {};
  }

  clear(): void {
    window.sessionStorage.removeItem(USER_DATA);
    window.sessionStorage.removeItem(USER_TOKEN);
  }
}
