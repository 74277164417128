<li class="nav-item text-light">

    <span class="mx-1">
        {{ userData?.firstname }} {{ userData?.lastname }}
    </span>

    <a (click)="signout()" class="btn btn-md rounded btn-red text-light" title="Logga ut">
        Logga ut
    </a>

</li>
