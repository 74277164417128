import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';

import { CookieService } from 'ngx-cookie-service';
import { provideToastr } from 'ngx-toastr';

import { AppErrorHandler } from './core/services/app-error-handler.service';
import { AuthenticationInterceptor } from './core/interceptor/authentication.interceptor';
import { errorToastrInterceptor } from './core/interceptor/error-toastr.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    provideAnimations(),
    provideToastr(),
    CookieService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    provideHttpClient(withInterceptors([errorToastrInterceptor]))
  ]
};
