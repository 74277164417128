import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class CurrentPageStateService {
  private currentPageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined!);
  public currentPage$: Observable<string> = this.currentPageSubject.asObservable();

  setCurrentPageTitle(title: string) {
    this.currentPageSubject.next(title);
    return this.currentPage$;
  }
}
