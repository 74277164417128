import { Injectable } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from './../../../environments/environment';

@Injectable()
export class AirbrakeNotifierService {
  notifier: Notifier;

  constructor() {
    this.notifier = new Notifier({
      projectId: environment.airbrake.projectId,
      projectKey: environment.airbrake.projectKey,
      environment: environment.envStr
    });
  }
}
