import { Component, Output, EventEmitter, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { User } from '../../models/user.model';

import { AuthenticationService } from '../../services/authentication.service';
import { CurrentPageStateService } from '../../services/current-page-state.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'pp-signout',
  templateUrl: './signout.component.html'
})
export class SignoutComponent {

  @Output() signOutEvent = new EventEmitter<void>();
  @Input() userData: User | undefined;

  #authenticationService = inject(AuthenticationService)
  #currentPageStateService = inject(CurrentPageStateService)

  signout() {
    this.#authenticationService.deAuthenticate();
    this.signOutEvent.emit();
    this.#currentPageStateService.setCurrentPageTitle('');
  }

}
