import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ErrorResponse } from '../models/error-response.model';

export const errorToastrInterceptor: HttpInterceptorFn = (req, next) => {

  const toastr = inject(ToastrService)

  return next(req).pipe(catchError(err => {

    let errorMessage: string = "Unknown error"

    if (err instanceof HttpErrorResponse) {

      // Client error
      if (err.error instanceof ErrorEvent) {
        toastr.error(`${err.error.message}`, `Ett fel inträffade`)
        return throwError(() => new Error(err.error.message))
      }

      // Backend error
      // Set default errorMessage - will be overriden if we got error response from backend services.
      errorMessage = `${err.statusText} - ${err.message}`

      if (err.error !== null && typeof err.error === 'object') {
        let errorResponse: ErrorResponse | undefined = undefined

        // Make sure that message is present in the response.
        if ('message' in err.error) {
          errorResponse = err.error as ErrorResponse
          errorMessage = errorResponse.message ?? errorMessage
        }
      }

      switch(err.status) {
        case 401:
        case 403:
          toastr.warning(errorMessage, `Åtkomst nekad`)
          break;

        case 404:
          toastr.warning(errorMessage, `Ej hittad`)
          break;

        default:
          toastr.error(errorMessage, `Ett fel inträffade`)
          break;
      }
    }

    return throwError(() => new Error(errorMessage))
  }))
};
