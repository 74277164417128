export const environment = {
  titleStr: "PRODUKTIONSPORTALEN",
  envStr: "DEVELOP",
  envBgClass: "bg-info",
  production: false,
  airbrake: {
    projectId: 481793,
    projectKey: '5115b5cebbac09603af70ad6ad7b0244'
  },
  frontendApi: 'https://production-portal-dev.svenskafonster.se/api'
};
