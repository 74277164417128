import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Router } from '@angular/router';

import jwtDecode, { JwtPayload } from "jwt-decode";

import { TokenService } from '../services/token.service';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // Skip token check if user signins.
    if (request.url.includes('/userservice/authenticate')) {
      return next.handle(request);
    }
    
    if (this.isTokenExpired()) {
      this.authenticationService.deAuthenticate();
      this.router.navigate(['/signin']);
      return EMPTY;
    }

    return next.handle(request);
  }

  isTokenExpired(): boolean {
    const token = this.tokenService.getToken();

    if (!token)  {
      return true;
    }

    const decodedToken = jwtDecode<JwtPayload>(token);

    if (decodedToken.exp == undefined) {
      return true;
    }

    return decodedToken.exp < (Date.now() / 1000);
  }

}
