import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UsersnapService } from './core/services/usersnap.service';
import { NavbarComponent } from './core/components/navbar/navbar.component';

@Component({
  standalone: true,
  selector: 'pp-root',
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    NavbarComponent
  ],
  providers: [UsersnapService]
})
export class AppComponent {

  constructor(private usersnapService: UsersnapService) {
    this.usersnapService.initialize();
  }
}
