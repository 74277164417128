import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { CookieData } from '../models/cookie.model';

const COOKIE_NAME: string = 'production-portal';
const COOKIE_MAX_AGE: number = 365;

@Injectable({
  providedIn: 'root'
})
export class LocalClientService {

  private cookieData: CookieData = {} as CookieData;

  constructor(private cookieService: CookieService) { }

  private setCookieData(): void {
    this.cookieService.set(
      COOKIE_NAME,
      JSON.stringify(this.cookieData),
      { expires: COOKIE_MAX_AGE, path: '/' }
    );
  }

  private setData(): void {
    const rawCookieData = this.cookieService.get(COOKIE_NAME);

    if (rawCookieData) {
      this.cookieData = JSON.parse(rawCookieData);
    }
  }

  hasData(): boolean {
    return this.cookieService.check(COOKIE_NAME);
  }

  deleteAll(): void {
    this.cookieService.delete(COOKIE_NAME);
    this.cookieService.deleteAll('/');
  }

  getWorkstationRoute(): string {
    this.setData();
    return this.cookieData ? this.cookieData.workstationRoute: '';
  }

  setLineRoute(slug: string): void {
    this.cookieData.lineRoute = slug;
    this.setCookieData();
  }

  setWorkstationRoute(slug: string): void {
    this.cookieData.workstationRoute = slug;
    this.setCookieData();
  }

  setLine(id: string, name: string, route: string)
  {
    this.cookieData.lineId = id;
    this.cookieData.lineName = name;
    this.cookieData.lineRoute = route;
    this.setCookieData();
  }

  getLineId(): string {
    this.setData();
    return this.cookieData ? this.cookieData.lineId : '';
  }

  getLineRoute(): string {
    this.setData();
    return this.cookieData ? this.cookieData.lineRoute : '';
  }

  getLineName(): string {
    this.setData();
    return this.cookieData ? this.cookieData.lineName.toLocaleUpperCase(): '';
  }

  setWorkstation(id: string, name: string, location: string)
  {
    this.cookieData.workstationId = id;
    this.cookieData.workstationName = name;
    this.cookieData.workstationLocation = location;
    this.setCookieData();
  }

  getWorkstationId(): string {
    this.setData();
    return this.cookieData ? this.cookieData.workstationId : '';
  }

  getWorkstationName(): string {
    this.setData();
    return this.cookieData ? this.cookieData.workstationName : '';
  }

  getWorkstationLocation(): string {
    this.setData();
    return this.cookieData ? this.cookieData.workstationLocation : '';
  }

}
