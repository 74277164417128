import { Routes } from '@angular/router';
import { AuthenticatedGuard } from 'src/app/core/guard/authenticated.guard';

export const routes: Routes = [
  ////
  // Core
  ////
  {
    path: '',
    redirectTo: '/signin',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    loadComponent: () => import('./core/components/signin/signin.component').then(c => c.SigninComponent),
    pathMatch: 'full'
  },
  {
    path: 'signout',
    loadComponent: () => import('./core/components/signout/signout.component').then(c => c.SignoutComponent),
    pathMatch: 'full'
  },
  ////
  // Features
  ////
  {
    path: 'start',
    loadComponent: () => import('./features/start/start.component').then(m => m.StartComponent),
    pathMatch: 'full',
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'workstation/:location',
    loadChildren: () => import('./features/workstation/workstation.routes')
  },
  {
    path: 'put-away-management',
    loadComponent: () => import('./features/put-away-management/put-away-management.component').then(m => m.PutAwayManagementComponent),
    pathMatch: 'full',
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'workstation-administration',
    loadComponent: () => import('./features/workstation-administration/workstation-administration.component').then(m => m.WorkstationAdministrationComponent),
    pathMatch: 'full',
    canActivate: [AuthenticatedGuard]
  },
  ////
  // Not found - this must be last.
  ////
  {
    path: '**',
    loadComponent: () => import('./core/components/not-found/not-found.component').then(c => c.NotfoundComponent)
  },
];
